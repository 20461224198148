#root {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100vh;
}
article {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

body {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #333;
}

textarea {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #333;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.datePicker {
  height: 48px;
  border: var(--common-border);
  border-radius: 4px;
  padding: 4px;
}

a {
  color: #333;
} /* Unvisited link  */
a:visited {
  color: #333;
} /* Visited link    */
a:hover {
  color: #333;
} /* Mouse over link */
a:active {
  color: #333;
} /* Selected link   */

:root {
  /* Common */
  --common-margin: 0.5em;
  --common-padding: 1em;
  --common-border: 1px solid #cccccc;
  --common-border-color: #cccccc;
  --common-border-radius: 4px;
  --common-outline-color: #0077ff;
  --common-transform-duration: 300ms;
  --common-font-size: 14px;
  /* */

  --nav-bar-selected: #db3b61;
  --nav-bar-selected-text: white;
  --button-color: dodgerblue;
  /* Button */
  --button-primary-bg-color: #db3b61;
  --button-primary-hover-color: #db3b61;
  --button-primary-font-color: #ffffff;
  --button-secondary-bg-color: #ffffff;
  --button-secondary-hover-color: #ffffff;
  --button-secondary-font-color: #db3b61;
  --button-tertiary-bg-color: transparent;
  --button-tertiary-hover-color: transparent;
  --button-tertiary-font-color: #2c2c2c;
  /* */

  /* Radio Buttons */
  --radio-button-size: 14px;
  --radio-button-size-inner: 10px;
  --radio-border-outer: #cccccc;
  --radio-border-inner: #cccccc;
  --radio-border-active-outer: #0077ff;
  --radio-border-active-inner: #0077ff;
  --radio-active-color: #0077ff;
  /* */

  /* Card Shadow */
  --card-width: 320px;
  --common-shadow: 0px 0px 5px #0000001a;
  /* */
}

.sponsearLoader svg {
  overflow: visible;
  width: 100px;
  height: 150px;
}
.sponsearLoader svg g {
  animation: slide 2s linear infinite;
}
.sponsearLoader svg g:nth-child(2) {
  animation-delay: 0.5s;
}
.sponsearLoader svg g:nth-child(2) path {
  animation-delay: 0.5s;
  stroke-dasharray: 0px 158px;
  stroke-dashoffset: 1px;
}
.sponsearLoader svg path {
  stroke: url(#gradient);
  stroke-width: 20px;
  stroke-linecap: round;
  fill: none;
  stroke-dasharray: 0 157px;
  stroke-dashoffset: 0;
  animation: escalade 2s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}
@keyframes slide {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(50px);
  }
}
@keyframes escalade {
  0% {
    stroke-dasharray: 0 157px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 156px 157px;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 156px 157px;
    stroke-dashoffset: -156px;
  }
}
