.label{
   width: 100%;
   display: flex;
   flex-direction: column;
   font-size: var(--common-font-size);
}

.textbox {
   display: flex;
   flex: 1;
   border: var(--common-border);
   padding: var(--common-padding);
   border-radius: var(--common-border-radius);
   background-image: none;
   background-color: transparent;
   -webkit-box-shadow: none;
   -moz-box-shadow: none;
   box-shadow: none;
   box-shadow: 0px 0px 3px #00000018;
   transition-duration: var(--common-transform-duration);
   font-size: var(--common-font-size);
}

.textbox:focus {
   outline-color: var(--common-outline-color);
   outline-width: 0px;
   outline-offset: 0px;
   box-shadow: inset 0px 0px 3px #00000018;
   transition-duration: var(--common-transform-duration);
}

.error{
   color: #c90101;
}

/* Chrome, Safari, Edge, Opera */
.textbox::-webkit-outer-spin-button,
.textbox::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.textbox[type=number] {
  -moz-appearance: textfield;
}

.textbox:read-only{
   cursor: not-allowed;
}
