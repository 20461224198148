.container {
  display: flex;
  flex-direction: row;
}
.button {
  border: none;
  padding: 12px 24px;
  text-decoration: none;
  background: var(--button-primary-bg-color);
  color: var(--button-primary-font-color);
  font-family: sans-serif;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 4px;
  transition-duration: 300ms;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27), 0 12px 26px 0 rgba(0, 0, 0, 0.2);
}

.button:hover,
.button:focus {
  outline: 0px;
}

.button:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.27), 0 6px 13px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--button-primary-hover-color);
  transition-duration: 300ms;
}

.button:active {
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.27), 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  transition-duration: 300ms;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.primary {
  background: var(--button-primary-bg-color);
  color: var(--button-primary-font-color);
}

.primary:hover {
  background-color: var(--button-primary-hover-color);
}

.small {
  padding: 8px 12px;
}

.secondary {
  background: var(--button-secondary-bg-color);
  color: var(--button-secondary-font-color);
}

.secondary:hover {
  background-color: var(--button-secondary-hover-color);
}

.borderOnly {
  background: transparent;
  color: var(--button-primary-bg-color);
  border: 1px solid var(--button-primary-bg-color);
}

.borderOnly:hover {
  background: transparent;
  color: var(--button-primary-bg-color);
  border: 1px solid var(--button-primary-bg-color);
}

.tertiary {
  background: var(--button-tertiary-bg-color);
  color: var(--button-tertiary-font-color);
  box-shadow: none;
  border: 1px solid #f3f0f0;
}

.tertiary:hover {
  background-color: var(--button-tertiary-hover-color);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.27), 0 0 0 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #ebe8e8;
}

.tertiary:active {
  background-color: var(--button-tertiary-hover-color);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.27), 0 0 0 0 rgba(0, 0, 0, 0.2);
  transform: scale(1);
  border: 1px solid #ebe8e8;
}

.noShadow {
  box-shadow: unset;
}

.spinner {
  cursor: wait;
  flex-direction: column;
  display: flex;
  align-items: center;
  :local {
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    svg {
      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

.child {
  visibility: hidden;
  height: 0px;
}

.wide {
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 0px;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.disabled:active {
  transform: scale(1);
}
